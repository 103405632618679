(function ($, window, document) {

    window.EWPPreferences = function (options) {
        if (typeof this.instance === 'object') {
            // The object was already created
            return this.instance;
        }

        // We have to initialize the object
        this.options = {
            preferences: {}
        };

        this.init = function (options) {
            $.extend(this.options, options);

        };

        this.get = function (preference) {
            return this.options.preferences[preference];
        };

        this.init(options);
        this.instance = this;
    };

    // Load the preferences
    $(document).ready(function () {
        var preferences = $.parseJSON($('#js-preferences').attr('data-preferences'));

        if (typeof preferences !== 'undefined') {
            window.EWPPreferences({
                preferences: preferences
            });
        }
    });

})(jQuery, window, document);
