$(function () {
    let form = $('.form-admin-hf');
    if (!form.length) {
        return;
    }

    let toggle = form.find('.is-facility-toggle'),
        userData = form.find('.rows-user');

    toggle.on('change', function () {
       if (this.checked){
           userData.show(500);
       } else {
           userData.hide(500);

       }
    }).triggerHandler('change');

    if (userData.hasClass('no-facility-user')) {
        userData.find('input').val('');
    }
});
