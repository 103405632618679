import { initializeDatePickers } from 'ehv-frontend';

/**
 * Vendor
 */
import './initialize/globals';
import 'popper.js';
import 'bootstrap';
import 'jquery-ui';
import 'moment/moment';
import 'datatables.net';
import 'datatables.net-bs4';
import 'datatables.net-responsive';
import 'blueimp-canvas-to-blob';
import 'blueimp-file-upload/js/jquery.iframe-transport';
import 'blueimp-file-upload/js/jquery.fileupload';
import 'blueimp-file-upload/js/jquery.fileupload-process';
import 'blueimp-file-upload/js/jquery.fileupload-image';
import 'blueimp-file-upload/js/jquery.fileupload-validate';
import 'jquery.maskedinput/src/jquery.maskedinput';
import 'tempusdominus-bootstrap-4';
import 'fullcalendar';
import 'fullcalendar/dist/locale/de';

/**
 * jQuery Plugins
 */
import './jquery-plugins/ewp-ajax-block';
import './jquery-plugins/ewp-ajax-search';
import './jquery-plugins/ewp-diagram';
import './jquery-plugins/ewp-file-upload';
import './jquery-plugins/ewp-modal-dialog';
import './jquery-plugins/ewp-multiple-file-upload';
import './jquery-plugins/ewp-form-collection-prototype';

/**
 * App
 */

import './initialize/preferences';
import './initialize/ewp-app-commons';
import './initialize/ewp-public-commons';
import './initialize/data-tables';
import './initialize/date-picker';
import './initialize/clickable-table';
import './initialize/mail-compose';
import './initialize/calendar';
import './initialize/tumorboard-dashboard';
import './initialize/admissions-list';
import './initialize/form-admin-hf';

function doInitializations() {
    initializeDatePickers();

}

$(doInitializations);
$('.form-collection-prototype').formCollectionPrototype();
