$(function () {
    var preferences = window.EWPPreferences();

    $('#calendar').fullCalendar({
        editable: true,
        eventLimit: true, // for all non-agenda views
        events: '../ajax/list-meetings',
        header: {
            center: 'title',
            left: 'prev,next today',
            right: 'month,agendaWeek,agendaDay',
        },
        locale: preferences.get('locale'),
        navLinks: true,
        themeSystem: 'bootstrap4',
        views: {
            agenda: {
                eventLimit: 6, // adjust to 6 only for agendaWeek/agendaDay
            },
        },
        weekNumbers: true,
    });
});
