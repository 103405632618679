(function ($, window, document, undefined) {
    var pluginName = 'ewpDiagram', dataKey = 'plugin_' + pluginName;

    var Plugin = function (element, options) {
        this.element = element;
        this.options = {
            'titleSelector': '.ewp-diagram-title',
            'chartSelector': '.ewp-diagram-chart',
            'modalSelector': '#ewp-diagram-modal',
            'onDialogLoad': function () {
            }
        };

        this.init(options);
    };

    Plugin.prototype = {
        init: function (options) {
            $.extend(this.options, options);
            var plugin = this;
            var $element = $(this.element);

            this.chartContainer = $(this.element).find(
                this.options.chartSelector);
            this.titleContainer = $(this.element).find(
                this.options.titleSelector);
            this.modalContainer = $(this.options.modalSelector);
            this.modalBody = this.modalContainer.find('.modal-body');
            this.modalTitle = this.modalContainer.find('.modal-title');
            this.modalClasses = this.modalContainer.attr("class").split(/\s+/);
            this.configUrl = this.chartContainer.attr("data-cfg");
            this.dataUrl = this.chartContainer.attr("data-src");
            this.dataClickUrl = $element.attr("data-on-click-url");
            this.chartDefinition = undefined;

            // Read the chart options
            this.chartOptions = $.parseJSON(this.chartContainer.attr("data"));

            // Initializa the chart
            this._initilizeChart();
        },

        _initilizeChart: function () {
            $.ajax({
                url: this.configUrl,
                type: 'get',
                dataType: 'json',
                context: this,
                success: function (data) {
                    // Set the default options for Highcharts
                    Highcharts.setOptions(data);

                    // Load the chart
                    this._loadChart();
                }
            });
        },

        _loadChart: function () {
            $.ajax({
                url: this.dataUrl,
                type: "post",
                data: JSON.stringify(this.chartOptions),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                context: this,
                success: function (data) {
                    this.chartDefinition = this._getChartDefinition(data.data);

                    // Workaround to disable the dafault behaviour when clicking the elements of the legend
                    if (this.chartDefinition.chart.type == "pie") {
                        this.chartDefinition.plotOptions.pie.point = {
                            events: {
                                legendItemClick: function () {
                                    return false;
                                }
                            }
                        };
                    }

                    // Initialize the chart
                    this.chart = this.chartContainer
                        .highcharts(this.chartDefinition);
                    // Set the title
                    if (typeof data.title !== 'undefined') {
                        this.titleContainer.text(data.title);
                    } else {
                        this.titleContainer.empty();
                    }
                }
            });
        },

        _onValueClick: function (plugin, element) {
            if (typeof plugin.dataClickUrl !== 'undefined') {
                var url = plugin.dataClickUrl.replace('__id__', element.id);

                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    context: plugin,
                    success: function (data) {
                        // Load the content in the modal container
                        this.modalBody.empty();
                        this.modalTitle.empty();
                        this.modalBody.append(data.content);
                        if (typeof data.title !== 'undefined') {
                            this.modalTitle.append(data.title);
                        }

                        // reset the classes
                        var classes = this.modalContainer.attr('class').split(/\s+/);
                        var plugin = this;
                        classes.forEach(function (value) {
                            plugin.modalContainer.removeClass(value);
                        });
                        this.modalClasses.forEach(function (value) {
                            plugin.modalContainer.addClass(value);
                        });

                        if (typeof data.modalClasses !== 'undefined') {
                            plugin.modalContainer.addClass(data.modalClasses);
                        }

                        // Show the modal
                        this.modalContainer.modal({
                            show: true,
                        });

                        this.options.onDialogLoad(this.modalContainer);
                    }
                });
            }
        },

        _getChartDefinition: function (data) {
            var plugin = this;
            data.plotOptions = data.plotOptions || {};
            data.plotOptions.series = data.plotOptions.series || {};
            data.plotOptions.series.point = data.plotOptions.series.point || {};
            data.plotOptions.series.point.events = data.plotOptions.series.point.events || {};
            data.plotOptions.series.point.events.click = function () {
                plugin._onValueClick(plugin, this);
            };

            return data;
        }
    };

    $.fn[pluginName] = function (options) {
        var args = arguments;

        if (options === undefined || typeof options === 'object') {
            // Creates a new plugin instance, for each selected element, and
            // stores a reference withint the element's data
            return this.each(function () {
                if (!$.data(this, 'plugin_' + pluginName)) {
                    $.data(this, 'plugin_' + pluginName, new Plugin(this,
                        options));
                }
            });
        } else if (typeof options === 'string' && options[0] !== '_'
            && options !== 'init') {
            // Call a public pluguin method (not starting with an
            // underscore) for each
            // selected element.
            if (Array.prototype.slice.call(args, 1).length == 0
                && $.inArray(options, $.fn[pluginName].getters) != -1) {
                // If the user does not pass any arguments and the method
                // allows to
                // work as a getter then break the chainability so we can
                // return a value
                // instead the element reference.
                var instance = $.data(this[0], 'plugin_' + pluginName);
                return instance[options].apply(instance, Array.prototype.slice
                    .call(args, 1));
            } else {
                // Invoke the speficied method on each selected element
                return this.each(function () {
                    var instance = $.data(this, 'plugin_' + pluginName);
                    if (instance instanceof Plugin
                        && typeof instance[options] === 'function') {
                        instance[options].apply(instance, Array.prototype.slice
                            .call(args, 1));
                    }
                });
            }
        }
    };
})(jQuery, window, document);