var getUrlParameter = require('./get-url-parameter');
var removeUrlParams = require('./remove-url-params');

$(document).ready(function () {
    if ($('#mail_compose_form').length > 0) {
        var placeholderChanger = document.getElementById("compose_mail_recipient");
        var placeholder_Changer = placeholderChanger.placeholder;
        placeholderChanger.addEventListener('focus', function() {
            this.placeholder = '';
        });
        placeholderChanger.addEventListener('blur', function() {
            this.placeholder = placeholder_Changer;
        });

        // Check for query parameter
        var recipientParam = getUrlParameter('mail');
        if (recipientParam) {
            var fieldValue = $('#compose_mail_to').val();

            if (!fieldValue.includes(recipientParam)) {
                appendEmailToToField(recipientParam);
            }

            removeUrlParams();
        }

        //initialize to field
        var value =  $('#compose_mail_to').val();
        var splitted_value = value.split(",");
        for(var n = 0; n < splitted_value.length; n++) {
            if(splitted_value[n] === '') {
                continue;
            }
            addTo(splitted_value[n]);
        }
    }

    // Search recipients functionality
    $("#compose_mail_recipient").on("keyup", function() {
        var path = $(this).parents('form').data('url');
        var value_username = $("#compose_mail_recipient").val();

        var value_clinic = $("#compose_mail_clinic").val();
        $.ajax({
            type: "GET",
            url: path,
            data: { username: value_username, clinicid: value_clinic },
            success: function (data) {
                if ($("#mail_user_selector").length) {
                    $("#mail_user_selector").remove();
                }
                $("#compose_mail_recipient").parent().append(data);
            }
        });
    });


    // Selection of a suggested recipient
    $('#mail_compose_form').on('click', '#mail_user_selector .mail_user', function() {
        $('#compose_mail_recipient').val('');

        var newValue_mail = $(this).attr('data-email');

        appendEmailToToField(newValue_mail);
        addTo(newValue_mail);

        $("#mail_user_selector").remove();
    });

    function appendEmailToToField(email) {
        var $field = $('#compose_mail_to'),
            oldFieldValue = $field.val(),
            newFieldValue;

        if (!oldFieldValue)
        {
            newFieldValue = email;
        }
        else
        {
            newFieldValue = oldFieldValue + ',' + email;
        }

        $field.val(newFieldValue);
    }


    var number_of_tos = 0;
    function addTo(newValue_mail) {

        number_of_tos++;
        var total = number_of_tos;

        var compose_tos_div = document.getElementById('area_tos');

        var container = document.createElement("div");
        container.setAttribute("style", 'margin-top: 5px;');

        var clonedInput = document.createElement("INPUT");
        clonedInput.setAttribute("type", "text");
        clonedInput.setAttribute("disabled", "disabled");
        clonedInput.setAttribute("value", newValue_mail);
        clonedInput.setAttribute("class", 'col-xs-11 col-md-11');
        var clonedId = "tos_view_input_" + total;
        clonedInput.setAttribute("id", clonedId);
        container.appendChild(clonedInput);

        var id = clonedId;
        var deleteMe = document.createElement("A");
        var linkText = document.createElement("I");
        linkText.setAttribute("class", "fa fa-trash-o fa-color");
        deleteMe.appendChild(linkText);
        deleteMe.setAttribute("class", "delete_to col-xs-1 col-md-1");
        deleteMe.setAttribute("href", "#");
        deleteMe.setAttribute("data-element", '#' + id);
        container.appendChild(deleteMe);

        compose_tos_div.appendChild(container);

    }
    // Deletes an existing input file or clears the last one
    $('#mail_compose_form').on('click', '.delete_to', function (e) {

        var thisElement = $(this);
        var row = thisElement[0].parentNode;

        var container =  document.getElementById('area_tos');
        var index = Array.prototype.slice.call(container.children).indexOf( row );

        $(row).remove();
        var form_input_to_field =  document.getElementById('compose_mail_to');

        var value = form_input_to_field.value;

        var splitted_value = value.split(',');
        var new_value = null;
        for(var n = 0; n < splitted_value.length; n++) {

            if(n === index) {
                continue;
            }

            if(new_value === null) {
                new_value = splitted_value[n];
            }
            else {
                new_value = new_value + ',' + splitted_value[n];
            }
        }

        form_input_to_field.value = new_value;

        e.preventDefault();
        return false;
    });

    // Adds a new input file
    $('#mail_compose_form').on('change', '.MailAttachment-input', function() {

        checkTotalFileSize();

        var $attachmentContainer = $(this).parent();

        var $deleteItem = $attachmentContainer.find('.MailAttachment-delete');
        if ($deleteItem.length === 0) {
            appendDeleteButton($attachmentContainer);
        }

        // Do not append input if last input has no value.
        var lastAttachmentValue = $('.MailAttachment').last().find('.MailAttachment-input').val();
        if (!lastAttachmentValue) {
            return;
        }

        var $clonedAttachment = $('.MailAttachment').first().clone();
        $clonedAttachment.find('.MailAttachment-input').val(null);
        $clonedAttachment.find('.MailAttachment-delete').remove();

        $('.MailAttachment').last().after($clonedAttachment);
    });

    function appendDeleteButton($elem) {
        var deleteButtonHtml = '<a class="MailAttachment-delete" href="#"><i class="fa fa-trash-o fa-color" aria-hidden="true"></i></a>';
        $elem.append(deleteButtonHtml);
    }

    // Deletes an existing input file or clears the last one
    $('#mail_compose_form').on('click', '.MailAttachment-delete', function(e) {
        e.preventDefault();

        var $attachmentContainer = $(this).parent();
        $attachmentContainer.remove();

        checkTotalFileSize();
    });

    function checkTotalFileSize() {
        var $attachmentErrorContainer = $('#attachment_error_container');
        var $form = $('#mail_compose_form');
        var total_attachment_size_limit_in_MB = $form.data('fileSizeLimit');

        //filesize is in byte
        var filesizes = 0;
        var $attachmentInputs = $('.MailAttachment-input');

        $attachmentInputs.each(function(index, input) {
            if (input.files === null) {
                return;
            }

            for (var i = 0; i < input.files.length; i++)
            {
                //filesize of input is in byte
                filesizes = filesizes + input.files[i].size;
            }
        });

        // convert mb to bytes for total_attachment_size_limit_in_MB
        if(filesizes > (total_attachment_size_limit_in_MB * 1048576)) {
            $attachmentErrorContainer.show();
        }
        else {
            $attachmentErrorContainer.hide();
        }
    }
});
