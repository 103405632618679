/**
 * Same as ewp-clickable, but with event delegation.
 * This is needed in order for the paginated tables to work.
 */

$('.Table--clickable').on('click', '.Table-row', function(e) {
    // Ignore event if link was clicked within clickable element.
    if (e.target.nodeName === 'I' || e.target.nodeName === 'A' || e.target.nodeName === 'BUTTON') {
        return;
    }

    var url = $(this).data('url');

    if (typeof url !== 'undefined') {
        window.document.location = url;
    }
});
