$(function () {

    $('.btn-trigger-popup').on('click', function(e){
        e.preventDefault();

        let popup = $('#modal-popup-cancel-confirmation'),
            action = popup.find('.btn-primary-action'),
            clicked = $(this);

        action.attr('href', clicked.data('url'));
        popup.modal('show');

    });
});
