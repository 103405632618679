(function ($, window, document, undefined) {
    var pluginName = 'ewpMultipleFileUpload', dataKey = 'plugin_' + pluginName;

    var Plugin = function (element, options) {
        this.element = element;
        this.options = {
            'labels': {},
        };

        this.init(options);
    };

    Plugin.prototype = {
        init: function (options) {
            $.extend(this.options, options);
            var plugin = this;
            var $element = $(this.element);

            this.options.labels.upload = this.options.labels.upload || 'Upload file';

            this.inputIndex = 0;

            this.inputs = new Array();
            this.newFileInput = undefined;

            this.inputsContainer = this._appendInputsContainer();
            this.filesContainer = this._appendFilesContainer();

            this._addInput($element.find('input[type="file"]'));

            this.uploadButton = this.filesContainer.find('button#upload-file').click(function () {
                if (typeof plugin.newFileInput !== 'undefined') {
                    plugin.newFileInput.click();
                }
                return false;
            });

        },

        _appendInputsContainer: function () {
            var inputsContainer = $('<div/>', {
                class: 'ewp-inputs-container',
                style: 'display: none;'
            });

            $(this.element).append(inputsContainer);
            return inputsContainer;
        },

        _appendFilesContainer: function () {
            var filesContainerElement = '<div class="ewp-files-container">'
                + '<div class="files"></div>'
                + '<button id="upload-file" class="btn btn-default">' + this.options.labels.upload + '</button>'
                + '</div>';
            var filesContainer = $(filesContainerElement);
            $(this.element).append(filesContainer);

            return filesContainer;
        },

        _addInput: function (input) {
            var plugin = this;
            this.inputs.push(input);
            this.inputsContainer.append(input);
            var index = this.inputIndex++;
            input.attr('data-index', index);
            input.change(function () {
                plugin._addFile(this, index);
            });

            this.newFileInput = input;
        },

        _addFile: function (input, index) {
            var plugin = this;

            var filename = $(input).val().split('\\').pop();

            // Add a new input for the next file
            var clonedInput = $(input).clone();

            // remove file from clonedInput otherwise the file would be saved
            // two times. 01.03.2018
            clonedInput.val("");

            this._addInput(clonedInput);

            var fileContainerHtml = '<div class="ewp-file-container" data-index="' + index + '">'
                + '<span class="filename">' + filename + '</span>'
                + '</div>';

            var deleteButton = $('<span class="delete"><i class="glyphicon glyphicon-remove"></i></span>');

            var fileContainer = $(fileContainerHtml);
            fileContainer.append(deleteButton);

            deleteButton.click(function () {
                plugin._removeFile(index);
                return false;
            });

            this.filesContainer.find('.files').append(fileContainer)

        },

        _removeFile: function (index) {
            var plugin = this;
            this.inputs.map(function (input) {
                var inputIndex = $(input).attr('data-index');
                if (index == inputIndex) {
                    $(input).remove();
                    $(plugin.filesContainer).find('div[data-index="' + index + '"]').remove();
                }
            });
        }
    };

    $.fn[pluginName] = function (options) {
        var args = arguments;

        if (options === undefined || typeof options === 'object') {
            // Creates a new plugin instance, for each selected element, and
            // stores a reference withint the element's data
            return this.each(function () {
                if (!$.data(this, 'plugin_' + pluginName)) {
                    $.data(this, 'plugin_' + pluginName, new Plugin(this,
                        options));
                }
            });
        } else if (typeof options === 'string' && options[0] !== '_'
            && options !== 'init') {
            // Call a public pluguin method (not starting with an
            // underscore) for each
            // selected element.
            if (Array.prototype.slice.call(args, 1).length == 0
                && $.inArray(options, $.fn[pluginName].getters) != -1) {
                // If the user does not pass any arguments and the method
                // allows to
                // work as a getter then break the chainability so we can
                // return a value
                // instead the element reference.
                var instance = $.data(this[0], 'plugin_' + pluginName);
                return instance[options].apply(instance, Array.prototype.slice
                    .call(args, 1));
            } else {
                // Invoke the speficied method on each selected element
                return this.each(function () {
                    var instance = $.data(this, 'plugin_' + pluginName);
                    if (instance instanceof Plugin
                        && typeof instance[options] === 'function') {
                        instance[options].apply(instance, Array.prototype.slice
                            .call(args, 1));
                    }
                });
            }
        }
    };
})(jQuery, window, document);