/**
 * https://stackoverflow.com/questions/22753052/remove-url-parameters-without-refreshing-page
 */

function removeUrlParams() {
    // are the new history methods available ?
    if(window.history !== undefined && window.history.replaceState !== undefined) {
        // if pushstate exists, add a new state the the history, this changes the url without reloading the page
        window.history.replaceState({}, document.title, window.location.pathname);
    }
}

module.exports = removeUrlParams;
