// https://symfony.com/doc/current/form/form_collections.html
$.fn.formCollectionPrototype = function () {

    if (!this.length) {
        return this;
    }

    this.each(function () {
        let holder = $(this);
        let opts = holder.data('opts');

        let $o = {
            formContent: '.form-collection-content',
            addLink: '.form-collection-add',
            deleteLink: '.form-collection-delete',
            rowClass: 'form-group form-group-collection clearfix',
            deleteLinkEl: '<i class="fa fa-trash-o form-collection-delete"></i>',
        };

        if (opts) {
            $.extend($o, opts);
        }

        let $obj = {
            init: function () {
                $($o.addLink, holder).on('click', function (e) {
                    e.preventDefault();
                    $obj.addItem($($o.addLink).attr('data-name'), $($o.addLink).attr('data-index'));
                });

                $('body').on("click", $o.deleteLink, function () {
                    $obj.deleteItem(this);
                });
            },
            addItem: function (parBasename, parIndexPosition) {
                let index = 0;
                // let lastEl = holder.find('div[id^="user_approveTimes_"]').last();
                let lastEl = holder.find('div[id^="'+parBasename+'"]').last();
                let indexId = lastEl.last().attr('id');
                console.log("found id: "+indexId);
                if (typeof indexId != 'undefined') {
                    let parts = indexId.split('_');
                    // index = parts[2];
                    index = parts[parIndexPosition];
                    if (isNaN(index)) {
                        index = 0;
                    } else {
                        index++;
                    }
                }

                let newEl = holder.data('collection-prototype');
                newEl = newEl.replace(/__name__/g, index);
                let newRow = $('<div class="' + $o.rowClass + '">' + $o.deleteLinkEl + '</div>').prepend(newEl);

                $($o.formContent).append(newRow);
            },
            deleteItem: function (el) {
                let classes = $o.rowClass.split(' ');
                let row = $(el).parents('.' + classes[0]);

                // table fallback
                if (!row.length) {
                    row = $(el).parents('tr');
                }

                row.fadeOut(250, function () {
                    row.remove();
                });
            }
        };

        $obj.init();

        return $obj;
    });
};


