import $ from 'jquery';

$('#ewp-modal-dialogs-modal').on('shown.bs.modal', function () {
    var preferences = window.EWPPreferences();
    var $datePickers = $('.ewp-datepicker');

    $datePickers.each(function (index, elem) {
        var $elem = $(elem);
        var options = {
            locale: preferences.get('locale'),
        };

        if ($elem.hasClass('date')) {
            $.extend(options, {
                format: 'L',
            });
        } else if ($elem.hasClass('datetime')) {
            $.extend(options, {
                sideBySide: true,
            });
        } else if ($elem.hasClass('time')) {
            $.extend(options, {
                format: 'LT',
            });
        }

        $elem.datetimepicker(options);
    });

})