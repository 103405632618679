function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');

    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam) {
            try {
                var rawValue = sParameterName[1],
                    decodedValue = decodeURIComponent(rawValue);

                return decodedValue;
            } catch(e) {
                console.error(e);
            }
        }
    }
}

module.exports = getUrlParameter;
