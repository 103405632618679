(function ($, window, document, undefined) {
    var pluginName = 'ewpFileUpload', dataKey = 'plugin_' + pluginName;

    var Plugin = function (element, options) {
        this.element = element;
        this.options = {
            url: '',
            previewMaxWidth: 100,
            previewMaxHeight: 100,
            messages: {
                abort: 'Abort',
                upload: 'Upload',
                upload_error: 'File upload failed.'
            },
            onFileUpload: function (result) {
            }
        };

        this.init(options);
    };

    Plugin.prototype = {
        init: function (options) {
            $.extend(this.options, options);
            var plugin = this;
            var $element = $(this.element);
            var preferences = window.EWPPreferences();

            this.uploaderConf = preferences.get('fileUploaderConf');
            this.fileInput = $element.find('input[type="file"]');
            this.fileInputButton = $element.find('.ewp-file-input-button');
            this.progressBar = $element.find('.ewp-file-progress');
            this.fileUploadButton = $element.find('.ewp-file-upload-button');
            this.fileContainer = $element.find('.ewp-file-upload-file');
            this.fileType = $element.attr('data-filetype');
            this.options.url = this.options.url.replace('__filetype__', this.fileType);

            this.fileData;

            // First we have to hide the file input and show the input button
            this.fileInput.hide();
            this.fileInputButton.show();

            // Hide the save button of the modal until the file is uploaded
            $('#ewp-modal-dialog-save-submit').hide();

            // Bind the click event to the input button
            this.fileInputButton.click(function () {
                plugin.fileInput.click();
            });

            // Bind the click event to the upload button
            this.fileUploadButton.on('click', function () {
                var $this = $(this);

                $this.off('click')
                    .text(plugin.options.messages.abort)
                    .on('click', function () {
                        plugin.fileData.abort();
                    });

                plugin.fileData.submit();
            });

            // Initialize the jQuery File Upload plugin
            this.fileInput.fileupload({
                url: this.options.url,
                dataType: 'json',
                autoUpload: true,
                acceptFileTypes: this._getFileTypesRegex(),
                disableImageResize: /Android(?!.*Chrome)|Opera/
                    .test(window.navigator.userAgent),
                previewMaxWidth: this.options.previewMaxWidth,
                previewMaxHeight: this.options.previewMaxHeight
            }).on('fileuploadadd', function (e, data) {
                // Add the file to the file container
                var node = $('<p/>')
                    .append($('<span/>').text(data.files[0].name));
                node.appendTo(plugin.fileContainer);
                plugin.fileData = data;
            }).on('fileuploadprocessalways', function (e, data) {
                var file = data.files[0];
                var node = $(plugin.fileContainer.children()[0]);

                if (file.error) {
                    node.replaceWith($('<span class="text-danger"/>').text(file.error));
                    data.files.pop();
                } else {
                    if (file.preview) {
                        node.prepend('<br/>')
                            .prepend(file.preview);
                    }

                    // Hide the add button and show the other elements
                    plugin.fileInputButton.hide();
                    plugin.progressBar.show();
                    plugin.fileUploadButton.show();
                }

                plugin.fileContainer.show();
            }).on('fileuploadprogressall', function (e, data) {
                var progress = parseInt(data.loaded / data.total * 100, 10);
                plugin.progressBar.find('.progress-bar').css(
                    'width',
                    progress + '%'
                );
            }).on('fileuploaddone', function (e, data) {
                plugin.options.onFileUpload(data.result);
                plugin.fileUploadButton.text(plugin.options.messages.upload)
                    .hide();
                plugin.progressBar.hide();
                // Show the save button of the modal
                $('#ewp-modal-dialog-save-submit').show();
            }).on('fileuploadfail', function (e, data) {
                // TODO

            }).prop('disabled', !$.support.fileInput)
                .parent().addClass($.support.fileInput ? undefined : 'disabled');
        },

        // Builds a regex for the jQuery File Upload plugin for the supported file types
        _getFileTypesRegex: function () {
            var pattern = '(\.|\/)(';
            var first = true;

            this.uploaderConf.filetypes.forEach(function (filetype) {
                if (!first) {
                    pattern += '|';
                }
                pattern += filetype;
                first = false;
            });
            pattern += ')$';

            return new RegExp(pattern, 'i');
        }

    };

    $.fn[pluginName] = function (options) {
        var args = arguments;

        if (options === undefined || typeof options === 'object') {
            // Creates a new plugin instance, for each selected element, and
            // stores a reference withint the element's data
            return this.each(function () {
                if (!$.data(this, 'plugin_' + pluginName)) {
                    $.data(this, 'plugin_' + pluginName, new Plugin(this,
                        options));
                }
            });
        } else if (typeof options === 'string' && options[0] !== '_'
            && options !== 'init') {
            // Call a public pluguin method (not starting with an
            // underscore) for each
            // selected element.
            if (Array.prototype.slice.call(args, 1).length == 0
                && $.inArray(options, $.fn[pluginName].getters) != -1) {
                // If the user does not pass any arguments and the method
                // allows to
                // work as a getter then break the chainability so we can
                // return a value
                // instead the element reference.
                var instance = $.data(this[0], 'plugin_' + pluginName);
                return instance[options].apply(instance, Array.prototype.slice
                    .call(args, 1));
            } else {
                // Invoke the speficied method on each selected element
                return this.each(function () {
                    var instance = $.data(this, 'plugin_' + pluginName);
                    if (instance instanceof Plugin
                        && typeof instance[options] === 'function') {
                        instance[options].apply(instance, Array.prototype.slice
                            .call(args, 1));
                    }
                });
            }
        }
    };
})(jQuery, window, document);