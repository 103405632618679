/**
 * These variables need to be global before some of the other vendor imports are made.
 */
import jQuery from 'jquery';
import Highcharts from 'highcharts';
import List from 'list.js';
import loadImage from 'blueimp-load-image';
import moment from 'moment/moment';
global.$ = global.jQuery = jQuery;
global.Highcharts = Highcharts;
global.List = List;
global.loadImage = loadImage;
global.moment = moment;
