import $ from 'jquery';

$(function () {
    if (!$('body').hasClass('app_tumorboard_dashbord')) {
        return;
    }

    $('#collapsible-collapse-tumorboard-list-archived').collapse();

    $('a[data-type="modal"]').on('click',
        function (e) {
            e.preventDefault();
            let action = ($(this).attr('data-action'));
            $('#form-' + action).attr('action', $(this).data('url'));
            $('#message-' + action).html($(this).data('message'));
            $('#modal-popup-modal-' + action).modal({show: true});
        });

    $('.non-clickable').on('click', function (e) {
        e.stopPropagation();
    });

    let batchArchiveButton = $('#tumorboard_action_batch_archive');
    let batchArchiveSelected = $('#batch-archive-selected');
    $('.non-clickable .checkbox').on('change', function () {
        let checked = $('.non-clickable .checkbox:checked');
        let selected = [];

        if (checked.length) {
            batchArchiveButton.removeClass('disabled');
        } else {
            batchArchiveButton.addClass('disabled');
        }

        checked.each(function (i, e) {
            selected.push(e.value);
        });

        batchArchiveSelected.val(selected.join(','));
    });
});
