/**
 * Common javascript file for all the app pages
 */

(function ($, window, document) {
  $(document).ready(function () {
    var preferences = window.EWPPreferences();

    var reloadAjaxBlocks = function () {
      $('.ewp-ajax-block').each(function () {
        $(this).ewpAjaxBlock('reloadBlock');
      });
    };

    var maskDateInputs = function(e) {
      var datetimeMask = preferences.get('datetimeMask');
//            var dateMask = preferences.get('dateMask');
      $(e).find("input.form-control.m7-datetime-input").mask(datetimeMask);
//            $(e).find("input.form-control.m7-date-input").mask(dateMask);
    };

    var bindPatientFilter = function () {
    };

    bindPatientFilter();

    var bindDepartmentsSearch = function () {
    };

    var bindEWPTriggerEvents = function (element) {
      // Load the EWP Modal dialog for the trigger elements
      $(element).find('.ewp-modal-dialog-trigger').each(function () {
        $(this).ewpModalDialog({

          onNewFormLoad: function (form) {
            $(form).find('.ewp-datepicker.datetime input').datetimepicker({
              format: preferences.get('datetimeFormat'),
              sideBySide: true,
              locale: preferences.get('locale')
            });

            $(form).find('.ewp-datepicker.date input').each(function () {

              $(this).datetimepicker({
                format: preferences.get('dateFormat'),
                locale: preferences.get('locale')
              });
            });
            bindDepartmentsSearch(form);
          },
          onSave: function () {
            reloadAjaxBlocks();
          },
          onDelete: function () {
            reloadAjaxBlocks();
          }
        });
      });
    };

    // Load the EWP Modal dialog for the trigger elements for the profile image management
    $('.ewp-modal-profile-image-edit').each(function () {
      $(this).ewpModalDialog({
        onNewFormLoad: function (form) {
          $(form).find('.ewp-file-upload').ewpFileUpload({
            url: preferences.get('fileUploadUrl'),
            onFileUpload: function (result) {
              var docId = result.id;
              $('#form_image_id').val(docId);
            }
          });
        },
        onSave: function () {
          location.reload();
        },
        onDelete: function () {
          location.reload();
        }
      });
    });

    // Initialize the EWP Modal dialog triger elements
    bindEWPTriggerEvents(document);

    maskDateInputs(document);

    // Initialize the ajax blocks
    $('.ewp-ajax-block').each(function () {
      $(this).ewpAjaxBlock({
        onReload: function (element) {
          bindEWPTriggerEvents(element);
        }
      });
    });

    // Bind the click to the javascript clickable elements
    $('.ewp-clickable').click(function(e) {
      // Ignore event if link was clicked within clickable element.
      if (e.target.nodeName === 'A') {
        return;
      }

      var url = $(this).data('url');

      if (typeof url !== 'undefined') {
        window.document.location = url;
      }
    });

    // Initiliaze the highcharts diagrams
    var charts = $(".ewp-diagram");
    charts.ewpDiagram({
      onDialogLoad: function (dialogContainer) {
        $(dialogContainer).find('.ewp-clickable').click(function () {
          var url = $(this).data('url');

          if (typeof url !== 'undefined') {
            window.document.location = url;
          }
        });
      }
    });

    // Initialize the ajax search
    $('[data-target="ajax-search"]').ewpAjaxSearch({
      onListLoad: function (list) {
        $(list).find('.ewp-clickable').click(function () {
          var url = $(this).data('url');

          if (typeof url !== 'undefined') {
            window.document.location = url;
          }
        });
      }
    });

    // Initialize the patient selector
    $('.patiente-nummber-select').change(function () {
      var selectedOption = $(this).find('option:selected');
      var destUrl = selectedOption.data('url');
      if (typeof destUrl !== 'undefined') {
        window.document.location = destUrl;
      }
    });

    $("#compose_mail_recipient").blur(function () {
      if (!$("#compose_mail_to").val()) {
        $("#compose_mail_recipient").val("");
      }
    });

    // Simulating button click on today, to show the calendar when we are going to select a date
    $(document).on('keyup', '#eh-modal-dialogs-modal', function (event) {
      if (event.keyCode == 27) {
        $('#eh-modal-dialogs-modal').modal('hide');
      }
    });

    $('#select-date-modal').on('shown.bs.modal', function () {
      $('.fc-today-button').trigger('click');
    });

    $('.remove-element').click(function (event) {
        event.preventDefault();
        var elementId = $(this).data("element");
        $('#' + elementId).remove();
    });

  });
})(jQuery, window, document);
