/**
 * Common javascript file for all the app pages
 */

(function ($, window, document) {
    $(document).ready(function () {

        var reloadAjaxBlocks = function () {
            $('.ewp-ajax-block').each(function () {
                $(this).ewpAjaxBlock('reloadBlock');
            });
        };

        var bindEWPTriggerEvents = function (element) {
            // Load the EWP Modal dialog for the trigger elements
            $(element).find('.ewp-modal-dialog-trigger').each(function () {
                $(this).ewpModalDialog({
                    onSave: function () {
                        reloadAjaxBlocks();
                    },
                    onDelete: function () {
                        reloadAjaxBlocks();
                    }
                });
            });
        };

        // Initialize the EWP Modal dialog triger elements
        bindEWPTriggerEvents(document);

    });
})(jQuery, window, document);