import { loadFromStorage, saveToStorage } from 'ehv-frontend';

var commonOptions = {
    language: languageOptions(language()),
    lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, 'Alle'],
    ],
    responsive: {
        details: false,
    },
};

var tableNames = [
    'admin',
    'admission',
    'admissionSelect',
    'clinicContact',
    'clinicUser',
    'mail',
    'medicalCase',
    'medicalCaseReminder',
    'meeting',
    'news',
    'portalUser',
    'personal',
    'equipment',
    'room',
    'requiredexpertise',
    'meetingtype',
    'new',
    'canceled',
    'confirmed',
    'archived',
];

var defaultOrder = {
    admin: [0, 'asc'],
    admission: [0, 'asc'],
    admissionSelect: [0, 'asc'],
    clinicContact: [2, 'asc'],
    clinicUser: [0, 'asc'],
    mail: [2, 'desc'],
    medicalCase: [0, 'asc'],
    medicalCaseReminder: [0, 'asc'],
    meeting: [0, 'asc'],
    news: [2, 'asc'],
    personal: [0, 'asc'],
    portalUser: [0, 'asc'],
    equipment: [0, 'asc'],
    room: [0, 'asc'],
    requiredexpertise: [0, 'asc'],
    meetingtype: [0, 'asc'],
    new: [0, 'asc'],
    canceled: [0, 'asc'],
    confirmed: [0, 'asc'],
    archived: [0, 'asc'],
};

function initDataTable() {
    tableNames.forEach(function(name) {
        var $table = $('.Table--' + name);

        if ($table.length === 0) {
            return;
        }

        var orderFromStorage = loadFromStorage('table.' + name + '.order');

        var options = $.extend({}, commonOptions, {
            order: [orderFromStorage && isOrderValid(orderFromStorage, $table) ? orderFromStorage : defaultOrder[name]],
            pageLength: loadFromStorage('table.' + name + '.pageLength'),
        });

        var table = $table.DataTable(options);

        $table.on('length.dt', function(e, settings, len) {
            saveToStorage('table.' + name + '.pageLength', len);
        });

        $table.on('order.dt', function(e, settings, order) {
            var column = order[0].col;
            var direction = order[0].dir;
            saveToStorage('table.' + name + '.order', [column, direction]);
        });

        $table.on('page.dt', function() {
            var page = table.page.info().page;
            saveToStorage('table.' + name + '.page', page);
        });

        $('.dataTables_length select').addClass('Table-lengthSelect custom-select');

        // Set page
        var pageFromStorage = loadFromStorage('table.' + name + '.page');
        if (pageFromStorage) {
            table.page(pageFromStorage).draw(false);
        }

        attachSearchInputHandler(table);
        attachSelectHandler(table);

        $.fn.dataTable.ext.search.push(appointmentSearchFunction);
    });
}

function isOrderValid(order, $table) {
    var numColumns = $table.find('thead th').length;
    var columnSetForOrdering = order[0];

    if (columnSetForOrdering >= numColumns) {
        return false;
    } else {
        return true;
    }
}

function language() {
    return $('html').attr('lang') || 'en';
}

function languageOptions(lang) {
    switch(lang) {
        case 'de':
            return {
                "sEmptyTable": "Keine Daten in der Tabelle vorhanden",
                "sInfo": "_START_ bis _END_ von _TOTAL_ Einträgen",
                "sInfoEmpty": "0 bis 0 von 0 Einträgen",
                "sInfoFiltered": "(gefiltert von _MAX_ Einträgen)",
                "sInfoPostFix": "",
                "sInfoThousands": ".",
                "sLengthMenu": "_MENU_ Einträge anzeigen",
                "sLoadingRecords": "Wird geladen...",
                "sProcessing": "Bitte warten...",
                "sSearch": "Suchen",
                "sZeroRecords": "Keine Einträge vorhanden.",
                "oPaginate": {
                    "sFirst": "Erste",
                    "sPrevious": "Zurück",
                    "sNext": "Nächste",
                    "sLast": "Letzte"
                },
                "oAria": {
                    "sSortAscending": ": aktivieren, um Spalte aufsteigend zu sortieren",
                    "sSortDescending": ": aktivieren, um Spalte absteigend zu sortieren"
                },
                select: {
                    rows: {
                        _: '%d Zeilen ausgewählt',
                        0: 'Zum Auswählen auf eine Zeile klicken',
                        1: '1 Zeile ausgewählt'
                    }
                }
            };
        default:
            return {};
    }
}

function attachSearchInputHandler(dataTable) {
    $('.Table-search').on('keyup', handleSearchTermChange);

    function handleSearchTermChange() {
        var searchTerm = $(this).val().trim();

        dataTable.search(searchTerm).draw();
    }
}

function attachSelectHandler(dataTable) {
    $('.Table-filter').on('change', handleSelectChange);

    function handleSelectChange() {
        dataTable.draw();
    }
}

function appointmentSearchFunction(settings, data) {
    var SELECT_CLASS = '.Table-filter';

    var FILTER_NONE = 'all',
        FILTER_MONTH = 'month',
        FILTER_WEEK = 'week',
        FILTER_DAY = 'today';

    // Do nothing if table is no appointment table.
    if (!settings.nTable.classList.contains('Table--meeting')) {
        return true;
    }

    var today = moment();
    var rowDate = moment(data[0], 'DD.MM.YYYY');
    var currentFilter = $(SELECT_CLASS).val();

    switch (currentFilter) {
    case FILTER_DAY:
        return rowDate.isSame(today, 'day');
    case FILTER_WEEK:
        return rowDate.isSame(today, 'week');
    case FILTER_MONTH:
        return rowDate.isSame(today, 'month');
    case FILTER_NONE:
    default:
        return true;
    }
}

$(document).ready(initDataTable);
