(function ($, window, document, undefined) {
    var pluginName = 'ewpAjaxSearch', dataKey = 'plugin_' + pluginName;

    var Plugin = function (element, options) {
        this.element = element;
        this.options = {
            onListLoad: function (list) {
            },
            searchMinLength: 3
        };

        this.init(options);
    };

    Plugin.prototype = {
        init: function (options) {
            $.extend(this.options, options);
            var plugin = this;
            var $element = $(this.element);

            this.dataUrl = $element.attr("data-src");
            this.input = $element.find("input[data-input='ajax-search']");
            this.resultContainer = $element.find('[data-target="ajax-search-container"]');
            this.params = {};

            // Bind the change event to the input
            this.input.keyup(function () {
                var text = $(this).val();
                if (text.length >= plugin.options.searchMinLength) {
                    plugin._search(text);
                } else {
                    // We have to clear the list if no search can be made
                    $(plugin.resultContainer).empty();
                }
            });
        },

        _search: function (text) {
            $.ajax({
                url: this.dataUrl,
                data: {
                    search: text
                },
                type: 'get',
                accept: 'text/html',
                context: this,
                success: function (data) {
                    // Replace the list with the new content
                    $(this.resultContainer).empty();
                    $(this.resultContainer).append(data);

                    // Call the on reload callback
                    this.options.onListLoad(this.element);
                }
            });
        }
    };

    $.fn[pluginName] = function (options) {
        var args = arguments;

        if (options === undefined || typeof options === 'object') {
            // Creates a new plugin instance, for each selected element, and
            // stores a reference withint the element's data
            return this.each(function () {
                if (!$.data(this, 'plugin_' + pluginName)) {
                    $.data(this, 'plugin_' + pluginName, new Plugin(this,
                        options));
                }
            });
        } else if (typeof options === 'string' && options[0] !== '_'
            && options !== 'init') {
            // Call a public pluguin method (not starting with an
            // underscore) for each
            // selected element.
            if (Array.prototype.slice.call(args, 1).length == 0
                && $.inArray(options, $.fn[pluginName].getters) != -1) {
                // If the user does not pass any arguments and the method
                // allows to
                // work as a getter then break the chainability so we can
                // return a value
                // instead the element reference.
                var instance = $.data(this[0], 'plugin_' + pluginName);
                return instance[options].apply(instance, Array.prototype.slice
                    .call(args, 1));
            } else {
                // Invoke the speficied method on each selected element
                return this.each(function () {
                    var instance = $.data(this, 'plugin_' + pluginName);
                    if (instance instanceof Plugin
                        && typeof instance[options] === 'function') {
                        instance[options].apply(instance, Array.prototype.slice
                            .call(args, 1));
                    }
                });
            }
        }
    };
})(jQuery, window, document);